<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="p-5 pb-lg-4 bg-dark">
                    <div class="d-flex align-item-center title mb-3">
                        <router-link to="/Adventure" style="margin-right: auto !important;margin-left: 0 !important;" class="btn btn-sm btn-outline-dark ml-auto">عرض المــزيد</router-link>
                        <h5 class="m-0 font-weight-normal">العــاب مغامرة</h5>
                    </div>
                    <div class="row" dir="rtl">
                        <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="game in adventure" :key="game.id">
                            <div class="osahan-card pointer">
                                <a @click="getFeed(game)">
                                    <img class="img-fluid" v-lazy="game.thumbnailUrl" alt="">
                                    <div class="osahan-card-body mt-3">
                                        <h6 class="text-dark mb-1">{{ game.title_ar }}</h6>
                                        <p class="mb-0 text-muted">مغامرة</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AdventureHome',   
   async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const adventure = ref([]);
      // await new Promise((res) => setTimeout(res, 20000));

      try {
        await HTTP.get(`CatogeryById.php?Cat_id=Adventure&LIMIT=8`).then((res) => {
          adventure.value = res.data.getCatogery; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (game) => {
        // if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
          router.push({ name: "GamesDetails", params: { gid: game.gid } });
        // } else {
        //   window.open("http://sudanipay.com/subscription/la3btna/la3btna", "_self");
        // }
      };

      return {
        getFeed,
        adventure
      };
   }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

</style>