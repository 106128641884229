import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFounds from '../views/Page/NotFounds.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/adventure',
    name: 'Adventure',
    component: () => import(/* webpackChunkName: "adventure" */ '../views/Categorys/Adventure.vue'),
  },
  {
    path: '/arcade',
    name: 'Arcade',
    component: () => import(/* webpackChunkName: "arcade" */ '../views/Categorys/Arcade.vue'),
  },
  {
    path: '/puzzles',
    name: 'Puzzles',
    component: () => import(/* webpackChunkName: "puzzles" */ '../views/Categorys/Puzzles.vue'),
  },
  {
    path: '/sports',
    name: 'Sports',
    component: () => import(/* webpackChunkName: "sports" */ '../views/Categorys/Sports.vue'),
  },
  {
    path: '/strategy',
    name: 'Strategy',
    component: () => import(/* webpackChunkName: "strategy" */ '../views/Categorys/Strategy.vue'),
  },
  {
    path: '/gamesDetails/:gid',
    name: 'GamesDetails',
    component: () => import(/* webpackChunkName: "gamesDetails" */ '../views/Page/GamesDetails.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/playGames/:gid',
    name: 'PlayGames',
    component: () => import(/* webpackChunkName: "playGames" */ '../views/Page/PlayGames.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue'),
  },
  {
    path: '/subscribes',
    name: 'Subscribes',
    component: () => import(/* webpackChunkName: "subscribes" */ '../views/User/Subscribes.vue'),
  },
  {
    path: '/otp',
    name: 'Otp',
    component: () => import(/* webpackChunkName: "otp" */ '../views/User/Otp.vue'),
  },
  {
    path: '/stcSub',
    name: 'StcSub',
    component: () => import(/* webpackChunkName: "stcSub" */ '../views/User/StcSub.vue'),
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/User/Unsubscribes.vue'),
    // meta: {
    //   IsAuth: true
    // }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFounds', component: NotFounds },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.getCookie("MINTUES") > 0 && $cookie.getCookie("status") == 1 && $cookie.getCookie("responseCode") == 1) {
      next();
    } else {
      next('/Subscribes')
    }
   } else {
     next();
   }
})
export default router
