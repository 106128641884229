<template>
    <nav class="navbar navbar-expand navbar-light bg-white osahan-nav-top p-0 px-2 sticky-top" style="margin-left: 0 !important;">
        <div class="container" style="padding: 0 5px;">
            <router-link to="/" class="navbar-brand mr-2">
                <!-- <img src="../../assets/image/SudaniWhiteLogo-NoShadow.png" alt=""> -->
            </router-link>
            <template v-if="showHeader">
                <div class="nav-item dropdown no-arrow  ">
                    <router-link to="/Unsubscribes" style="background: #315b10 !important;color: #fff;border: 1px solid #67bb27;border-radius: 5px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15" >الغاء الاشتراك</router-link>
                </div>
            </template>
            <template v-else>
                
                <template v-if="HeaderZainM">
                    <div class="nav-item dropdown no-arrow  ">
                        <a style="background: #315b10 !important;color: #fff;border: 1px solid #67bb27;border-radius: 5px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15" >{{ this.$cookie.getCookie("msisdn") }}</a>
                    </div>
                </template>
                <template v-else>
                    <div class="nav-item dropdown no-arrow  ">
                        <a style="background: #315b10 !important;color: #fff;border: 1px solid #67bb27;border-radius: 5px;" @click="gotoSub()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15 pop-outin" >إشتــراك</a>
                    </div>
                    <div class="nav-item dropdown no-arrow  ">
                    <router-link to="/Login" style="background: #315b10 !important;color: #fff;border: 1px solid #67bb27;border-radius: 5px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                </div>
                </template>
            </template>     
            <form style="margin-left: 25px !important;" class="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                    <input type="text" class="form-control shadow-none border-0" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button">
                            <i class="feather-search"></i>
                        </button>
                    </div>
                </div>
            </form>
            <ul class="navbar-nav ml-auto d-flex align-items-center">
                <!-- <li class="nav-item dropdown no-arrow d-sm-none">
                    <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="feather-search mr-2"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown">
                        <form class="form-inline mr-auto w-100 navbar-search">
                            <div dir="rtl" class="input-group">
                                <input type="text" style="margin-right: 5px;" class="form-control border-0 shadow-none" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button class="btn" type="button" style="border-radius: 12px 0 0 12px;">
                                        <i class="feather-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li> -->
                <!-- <template v-if="showHeader">
                    <li class="nav-item dropdown no-arrow  ">
                        <router-link to="/Unsubscribes" style="background: #315b10 !important;color: #fff;border: 1px solid #67bb27;border-radius: 5px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >الغاء الاشتراك</router-link>
                    </li>
                </template>
                <template v-else> -->
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <a style="padding-right: 0;" class="nav-link dropdown-toggle" href="#" id="searchDropdown22" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i style="font-size: 21px;" class="feather-user mr-2"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown22">
                            <form class="form-inline mr-auto w-100 navbar-search">
                                <div dir="rtl" class="input-group">
                                    <h6 style="background: transparent;min-height: 35px;font-size: 14px;font-weight: 600;color: #fff;text-align: right;" class="form-control border-0 shadow-none">  سجل دخولك  وتمتع بتسلية فريدة من نادي اللعب</h6>
                                    <div class="input-group-append">
                                     <router-link to="/Login" style="background: #315b10 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li> -->
                <!-- </template> -->
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <router-link to="/Login" style="background: #315b10 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                    </li> -->
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <a style="background: #315b10 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" @click="gotoSub()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >إشتــراك</a>
                    </li> -->
                    <li class="nav-item dropdown mr-2">
                        <a class="nav-link dropdown-toggle pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            الالعاب
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow-sm">
                            <router-link to="/Puzzles" class="dropdown-item">الغاز</router-link>
                            <router-link to="/Arcade" class="dropdown-item">تحدي</router-link>
                            <router-link to="/Strategy" class="dropdown-item">أكشن</router-link>
                            <router-link to="/Adventure" class="dropdown-item">مغامرة</router-link>
                            <router-link to="/Sports" class="dropdown-item">رياضية</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown mr-2">
                        <router-link to="/" class="nav-link  pr-0"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            الرئيسية
                        </router-link>
                    </li>
                    <router-link to="/" class="navbar-brand mr-2" style="margin-right: 5px !important;"><img style="height: 26px;" src="../../assets/image/yallagame.png"  alt=""></router-link>
                </ul>
            </div>
        </nav>


</template>

<script>
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { HTTPSPA } from '@/Api/http-Spay';
import { useCookie } from 'vue-cookie-next'
export default {
    name: 'AppHeader',
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const showHeader = ref(false);
        const HeaderZainM = ref(false);

        watchEffect(() => {
            
            if (cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {

                if (cookie.getCookie('showHaeder') == '3754') {
                    showHeader.value = true;
                } else if (cookie.getCookie('showHaeder') == '3595') {
                    // console.log('Zain')
                    HeaderZainM.value = true;
                } else if (cookie.getCookie('showHaeder') == '3596') {

                    HeaderZainM.value = true;
                } else {
                    showHeader.value = false;
                }

                HTTPSPA.post(`CheckLogin.php?msisdn=${cookie.getCookie("msisdn")}&password=${cookie.getCookie("password")}`).then((res) => {
                    if (res.data.status == 1 && res.data.MINTUES > 0 ) {
                        return;
                    } else {
                        // cookie.removeCookie("MINTUES");
                        // cookie.removeCookie("msisdn");
                        cookie.removeCookie("MINTUES");
                        cookie.removeCookie("responseCode");
                    }
                    });
                
            } 
        }); 
        
        const gotoSub = () => {
            router.push({ name: "Subscribes" });
        }
        return { gotoSub, showHeader, HeaderZainM }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.pop-outin {
  animation: 2s anim-popoutin ease infinite;
}

@keyframes anim-popoutin {
  0% {
    color: rgb(190, 182, 182);
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: #fff;
    transform: scale(1.3);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: rgb(184, 176, 176);
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}
</style>