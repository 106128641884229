<template>
  <figure>
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
</figure>
</template>

<script>
export default {
   name: 'AppLoading'
}
</script>

<style scoped>
body { background: #222; }
figure { 
  position: relative;
 margin: auto;
  margin-top: auto;
  margin-bottom: auto;  
/* top: 0;
bottom: 0;
left: 0;
right: 0; */
width: 6.250em;
height: 6.250em;
-webkit-animation: rotate-0ce65ca0 2.4s linear infinite;
animation: rotate-0ce65ca0 2.4s linear infinite;
display: flex;
margin-top: 40vh;
margin-bottom: 60vh;
}
.white { 
  top: 0; bottom: 0; left: 0; right: 0; 
  background: white; 
  animation: flash 2.4s linear infinite;
  opacity: 0;
}
.dot {
  position: absolute;
  margin: auto;
  width: 2.4em; height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}
.dot:nth-child(2) { top: 0; bottom: 0; left: 0; background: #315b10; animation: dotsY 2.4s linear infinite; }
.dot:nth-child(3) { left: 0; right: 0; top: 0; background: #67bb27; animation: dotsX 2.4s linear infinite; }
.dot:nth-child(4) { top: 0; bottom: 0; right: 0; background: #315b10; animation: dotsY 2.4s linear infinite; }
.dot:nth-child(5) { left: 0; right: 0; bottom: 0; background: #67bb27; animation: dotsX 2.4s linear infinite; }

@keyframes rotate {
  0% { transform: rotate( 0 ); }
  10% { width: 6.250em; height: 6.250em; }
  66% { width: 2.4em; height: 2.4em; }
  100%{ transform: rotate(360deg); width: 6.250em; height: 6.250em; }
}

@keyframes dotsY {
  66% { opacity: .1; width: 2.4em; }
  77%{ opacity: 1; width: 0; }
}
@keyframes dotsX {
  66% { opacity: .1; height: 2.4em;}
  77%{ opacity: 1; height: 0; }
}

@keyframes flash {
  33% { opacity: 0; border-radius: 0%; }
  55%{ opacity: .6; border-radius: 100%; }
  66%{ opacity: 0; }
}
.fuckU_footer{
    position: fixed;
right: 0;
left: 0;
bottom: 0;
}
@media(max-width:768px){
    
    .fuckU_footer{
        position: relative;
    }
}
</style>